<script>
    import {onMount} from "svelte";

    onMount(() => {
        const script = document.createElement('script');
        script.src = 'https://widget.senja.io/widget/f6259062-ede3-42fa-a23c-e03a2aca7b81/platform.js';
        script.type = 'text/javascript';
        script.async = true;
        document.body.appendChild(script);
    });
</script>

<section>
    <h2>
        What do others say?
    </h2>
    <div class="senja-embed" data-id="f6259062-ede3-42fa-a23c-e03a2aca7b81" data-mode="shadow" data-lazyload="false"
         style="display: block;"></div>
</section>

<style>
    section {
        margin-bottom: 4rem;
    }
</style>