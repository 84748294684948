<script>
    function autoplayWhenInView(audioElement) {

        const observer = new IntersectionObserver(play)
        observer.observe(audioElement)

        return {
            destroy() {
                observer.disconnect()
            }
        };
    }

    async function play(entries) {
        for (const entry of entries) {
            const audioElement = entry.target
            if (entry.isIntersecting) {
                await audioElement.play()
            } else {
                audioElement.pause()
            }
        }
    }
</script>

<section>

    <h2>How to use?</h2>
    <p>This video provides a quick overview of how to use <b>vocabeo</b> »</p>
    <!-- svelte-ignore a11y-media-has-caption -->
    <video src="/intro-video.mp4"
           controls
           preload="metadata"
           width="100%"
           poster="/intro-video-poster.jpg"
           muted
           playsinline
           loop
           use:autoplayWhenInView
    >
    </video>
</section>


<style>
    section {
        margin: 0 0 8rem 0;
    }

    video {
        border: 2px solid #f2f2f2;
        box-shadow: var(--shadow-big);
    }

</style>