<script>
    import PageWrapper from "$lib/components/_page/PageWrapper.svelte";
    import Home from "$lib/components/Home.svelte";

</script>

<svelte:head>
    <title>vocabeo - Study the most frequent German words</title>

    <meta name="description"
          content="Build up your German vocabulary fast with the unique combination of dictionary and vocabulary trainer with spaced repetition.">
</svelte:head>

<PageWrapper footer={true}>

    <Home/>

</PageWrapper>







