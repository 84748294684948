<script>
    import Logo from "$lib/components/assets/Logo.svelte";
    import DictVocabTrainer from "$lib/components/_home/DictVocabTrainer.svelte";
    import AboutVocabeo from "$lib/components/_home/AboutVocabeo.svelte";
    import IntroVideo from "$lib/components/_home/IntroVideo.svelte";
    import {device} from "$lib/stores/ui/device.js";
    import Testimonials from "$lib/components/_home/Testimonials.svelte";
    import Pricing from "$lib/components/_home/Pricing.svelte";
    import VocabeoSupplements from "$lib/components/_home/VocabeoSupplements.svelte";
</script>

<div id="home">

    <Logo size="home"/>

    <h1>
        Learn the most frequent German words - quickly & effectively
    </h1>

    <AboutVocabeo/>

    <DictVocabTrainer/>

    {#if $device === 'desktop'}
        <IntroVideo/>
    {/if}

    <Testimonials/>

    <Pricing/>

    <VocabeoSupplements/>

</div>

<style>
    #home {
        border-radius: var(--border-radius);
        background: white;
        padding: 6rem 6rem;
    }

    /*noinspection CssUnusedSymbol*/
    #home :global(p.higher) {
        line-height: 1.8;
    }

    h1 {
        font-weight: var(--font-normal);
        margin: 3.6rem 0 2.6rem 0;
    }

    @media (prefers-color-scheme: dark) {
        #home {
            background: var(--bg);
        }
    }

    @media (max-width: 1000px) {

        #home {
            padding: 2.5rem 1.5rem;
        }

        h1 {
            font-size: 1.05rem;
            margin: 1.4rem 0 1.8rem 0;
            line-height: 1.4;
        }
    }
</style>