<script>
</script>

<section>
    <h2>
        How much does it cost?
    </h2>
    <p class="higher">
        <b>vocabeo</b> comes with a generous <span class="emph">free</span> plan with access to the dictionary and the
        ability to save words. However, you're limited to learning <span class="underline">up to 20 words per day</span>.
        After signing up, enjoy a <span class="underline">one-week</span> <span class="emph">trial</span> of the Pro
        plan. Upgrade to <span class="emph">Pro</span> to unlock unlimited learning potential!
    </p>

    <a
            href="/pricing"
            class="link-button"
            data-testid="link-to-pricing"
    >
        » See pricing
    </a>
</section>

<style>
    section {
        margin-bottom: 4rem;
    }

    .emph {
        display: inline-grid;
        place-content: center;
        line-height: normal;
        padding: .12rem .4rem;
        border-radius: .2rem;
        background: var(--color-Verb);
        color: white;
        font-weight: 600;
    }

    .underline {
        font-style: italic;
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-decoration-color: var(--color-Verb);
        text-underline-offset: 4px;
    }

</style>